import { useDispatch, useSelector } from "react-redux"
import data, { timezone, urlapi } from "../../../lib/backend/data"
import { useEffect, useState } from "react"
import { cerrarSesion } from "../../../redux/actions/sesion"
import { Avatar, Button, Card, Col, DatePicker, Drawer, Form, Input, Pagination, Progress, Row, Space, Table, Tooltip, message } from "antd"
import CargandoTabla from "./cargando"
import Column from "antd/es/table/Column"
import Title from "antd/es/typography/Title"
import Paragraph from "antd/es/typography/Paragraph"
import { calcularPorcentaje, fechaATextoSimple, formatYMD } from "../../../lib/helpers/helpers"
import { Link } from "react-router-dom"
import { rutas } from "../../../lib/routes/routes"
import { formatDateHoy, obtenerFechaHoraZonaHorariaLocal } from "../../../lib/helpers/dates"
import { AntDesignOutlined, FileExcelFilled, MobileOutlined, QuestionCircleOutlined, SearchOutlined, UserOutlined } from "@ant-design/icons"
import { formatearDNIPorPais, formatearMovilPorPais, nombreDNIPorPais } from "../../../lib/helpers/data/internationa"
import { BsBoxArrowInUpRight } from "react-icons/bs"
import dayjs from "dayjs"
import ButtonGroup from "antd/es/button/button-group"
import locale from 'antd/es/date-picker/locale/es_ES';
import { DateTime } from "luxon"
import { IoMdArrowRoundBack } from "react-icons/io"
import { FaUserAlt } from "react-icons/fa"

const ListadoConductoresRastreo = (props)=> {
    const {
        condicion_default,
        tableSize,
        fecha,
        hideExporter,
        hideSearch,
        tituloHoverDriver,
        hideHeader,
        typeView
    } = props
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const [ conductores, setConductores] = useState([])
    const [ loadingExcel, setLoadingExcel ] = useState(false)
    const ayer = DateTime.now().setZone(timezone).minus({ day: 1 }).toJSDate().toISOString()
    const [ fechaConsulta, setFechaConsulta ] = useState(formatYMD(ayer))
    const [ filtros, setFiltros] = useState([])
    const [ intervalo, setIntervalo] = useState(false)
    const [ loadingConductores, setLoadingConductores] = useState(true)
    const [ loadingCoordenadas, setLoadingCoordenadas ] = useState(false)
    const [ messageApi, contextHolder] = message.useMessage();
    const [ conductorSeleccionado, setConductorSeleccionado ] = useState(false)
    const [ pagina, setPagina ] = useState(1)
    const [ campoBusqueda, setCampoBusqueda ] = useState('')
    const [ total, setTotal ] = useState(0)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(condicion_default ? condicion_default : {})
    const trash = props.trash ? props.trash : false
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const [ isFiltered, setIsFiltered ] = useState(false)
    const tipo = props.tipo ? props.tipo : "link"
    const titulo = props.title ? props.title : 'registros'
    let titulo_tooltip = tituloHoverDriver ? tituloHoverDriver : "Toca para ver en el mapa"
    const [open, setOpen] = useState(true)

    const showDrawer = () => {
        setOpen(true);
    }

    const onClose = () => {
        setOpen(false);
    }


    const obtenerConductores = async (page, query, forze)=>{
        setLoadingConductores(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        setCondicionBusqueda(condicion)
        return fetch(`${urlapi}/informes/conductores/tracking/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page,
                fecha: fechaConsulta,
                forze
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoadingConductores(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoadingConductores(false)
            }
            if(Array.isArray(res.datos) !== false){
                setConductores(res.datos)
                if(conductorSeleccionado){
                    const i = res.datos.findIndex(con => con._id === conductorSeleccionado._id)
                    seleccionarRegistro(res.datos[i])
                }
                setTotal(res.total)
                if(res.filtros) setFiltros(res.filtros)
            }
            return setLoadingConductores(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingConductores(false)
        })
    }

    useEffect(() => {
        obtenerConductores(1, false)
    }, [])

    const handleChangePagina = (e) => {
        setPagina(e)
        obtenerConductores(e, condicion_busqueda)
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        if(!cantidad) return false
        return <div className="mb-3">
            <Pagination showSizeChanger={false} current={pagina} total={(cantidad*10)} onChange={handleChangePagina} />
        </div>
    }

    const seleccionarRegistro = data => {
        if(props.onSelect) props.onSelect(data)
        setConductorSeleccionado(data)
        onClose()
        if(!data) return showDrawer()
        data.fecha = fechaConsulta
        var divElement = document.getElementById("box_conductores");
        if(divElement) divElement.scrollTo({
            top: 0,
            behavior: "smooth" // Agrega una animación suave
        });
    }

    const showByType = (tipo, data) => {
        switch (tipo) {
            case "funcion":
                return <b className="hover" onClick={() => seleccionarRegistro(data)}>{data.nombres} {data.apellido_p ? data.apellido_p : ''}</b>
            default:
                return <Link to={`${rutas.drivers.slug}/${data._id}`}>{data.nombres} {data.apellido_p ? data.apellido_p : ''}</Link>
        }
    }

    const filtrarRegistros = (filtro) => {
        if(!filtro.condicion) return false
        if(typeof filtro.condicion !== "object") return false
        obtenerConductores(1, filtro.condicion)
    }

    const header = () => {
        if(hideHeader === true) return false
        return <Row gutter={15}>
            {
                filtros.map((filtro,i) => {


                    const porcentaje = calcularPorcentaje(total, filtro.cantidad)
                    return <Col className="hover" md={3} key={`filtro-${i}`} onClick={() => filtrarRegistros(filtro)} >
                    <Card size="small" className="mb-3">
                        <Paragraph level={3} style={{ fontSize: 12 }} className="m-0">{filtro.label}
                        {
                            filtro.help_text ? <Tooltip title={filtro.help_text}>
                           <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                          </Tooltip> : false
                        }
                        </Paragraph>
                        <Row>
                            <Col xs={12}><Title className="m-0">{filtro.cantidad}</Title></Col>
                            <Col xs={12} style={{ textAlign: "right", marginTop:8 }}> <Progress size={35} type="circle" percent={porcentaje} /></Col>
                        </Row>
                    </Card>
                </Col>
                })
            }
        </Row>
    }

    const descargarExcel = async () => {
        setLoadingExcel(true)
        return fetch(`${data.urlapi}/reports/drivers`,{
            method:'POST',
            body: JSON.stringify({
                condicion: condicion_busqueda
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.blob()
        })
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            const fecha = obtenerFechaHoraZonaHorariaLocal()
            a.download = `reporte-${fecha}.xlsx`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();
            return setLoadingExcel(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingExcel(false)
        })
    }

    const handleChangeCampo = (e) => {
        const { value } = e.target;
        return setCampoBusqueda(value);
      };

      const reiniciarIntervalo = (pagina, cond) => {
        clearInterval(intervalo)
        const id_intervalo = setInterval(() => {
            obtenerConductores(pagina, cond, false)
          }, 4000);
        setIntervalo(id_intervalo)
      }


      const handleSubmit = (e) => {
        if(e) e.preventDefault()
        if (!campoBusqueda){
            setIsFiltered(false)
            setCondicionBusqueda(condicion_default);
            setPagina(1);
            return obtenerConductores(1, condicion_default)
        }
        setIsFiltered(true)
        const condicion = {
            ...condicion_default,
          $text: { $search: campoBusqueda.toLowerCase() }
        };
        setCondicionBusqueda(condicion);
        setPagina(1);
        return obtenerConductores(1, condicion);        
      };
      
      const buscador = () => {
        if(hideSearch === true) return false
        return <Form onSubmitCapture={handleSubmit}>
        <Row gutter={15}>
            <Col md={10}>
                <Form.Item label="Buscar">
                    <Input autoFocus defaultValue={campoBusqueda} onChange={handleChangeCampo} placeholder={`Nombre, apellido, email, teléfono o ${nombreDNIPorPais(pais)}`} />
                </Form.Item>
            </Col>
            <Col md={14}>
                <Form.Item>
                    <ButtonGroup style={{ width: "100%"}}>
                    <Button onClick={handleSubmit} type="primary" ><SearchOutlined /> BUSCAR</Button>
                    <Button disabled={!isFiltered} onClick={() => {
                        setIsFiltered(false)
                        obtenerConductores(1, {})
                        setCampoBusqueda('')
                        }} ><SearchOutlined /> REINICIAR BÚSQUEDA</Button>
                    </ButtonGroup>
                </Form.Item>
            </Col>
        </Row>
               
                </Form>
      }

      const mostrarExportador = () => {
        if(hideExporter === true) return false
        return <div><Button loading={loadingExcel} style={{ marginRight: 10, marginBottom: 15 }} color="green" type="primary" size="small" onClick={() => descargarExcel()}><FileExcelFilled /> EXPORTAR</Button></div>
      }

      const mostrarInfoVehiculo = (veh) => {
        if(!veh) return false
        if(typeof veh !== "object") return false
        let modelo      = ''
        let marca       = ''

        if(typeof veh.modelo === "object") if(veh.modelo.titulo) modelo = veh.modelo.titulo
        if(typeof veh.marca === "object") if(veh.marca.titulo) marca = veh.marca.titulo

        return <div>{marca} {modelo} {veh.patente} <a target='_blank' href={`${rutas.vehicles.slug}/${veh._id}`}><BsBoxArrowInUpRight /></a></div>
    }

    const handleChangeDate = (e) => {
        let valor = ''
        if(e) valor = formatDateHoy(e)
        setFechaConsulta(valor)
    }

    const mostrarResumenInforme = (informe) => {
        if(!informe) return false
        if(typeof informe !== "object") return false

        let km = obtenerDatoInforme(informe, "distancia_km_recorrida")
        if(!isNaN(parseFloat(km))) km = parseFloat(km).toFixed(2)
        let validar_distancia = km ? km : 0
        if(validar_distancia < 1 && validar_distancia > 0 ) validar_distancia = "Menos de 1"

        let paradas = obtenerDatoInforme(informe, "paradas")
                    let steps = 'Sin datos'
                    if(paradas) if(Array.isArray(paradas) !== false) steps = paradas.length
        
        let numero = obtenerDatoInforme(informe, "minutos_conexion")

        return <Card size="small" className="mb-0 mt-3">
            <Row>
                <Col md={9}>
                    <Paragraph className="m-0">Tiempo en línea</Paragraph>
                    <Title level={5} className="mt-0 mb-0">{formatTime(numero)}</Title>
                </Col>
                <Col md={6}>
                    <Paragraph className="m-0">Paradas</Paragraph>
                    <Title level={5} className="mt-0 mb-0">{steps}</Title>
                </Col>
                <Col md={9}>
                    <Paragraph className="m-0">Dist. recorrida</Paragraph>
                    <Title level={5} className="mt-0 mb-0">{validar_distancia === 0 ? "Sin datos" : `${validar_distancia} Km.`}</Title>
                </Col>
            </Row>
        </Card>
    }

    const mostrarSeleccionado = () => {
        if(!conductorSeleccionado) return false

        return <Card size="small" style={{ marginTop: 20, position: "fixed", zIndex: 9, right: 50, bottom: 50 }} >
                    <Button className="mb-3" onClick={() => seleccionarRegistro(false)} icon={<IoMdArrowRoundBack />}> SELECCIONAR OTRO CONDUCTOR</Button>
            <Row>
                <Col md={16} xs={18}>
                    <Title level={4} className="mb-0 mt-0">{conductorSeleccionado.nombres} {conductorSeleccionado.apellido_p} {conductorSeleccionado.apellido_m} <Link target="_blank" to={`${rutas.drivers.slug}/${conductorSeleccionado._id}`}><BsBoxArrowInUpRight /></Link></Title>
                    <Paragraph className="mb-0"><MobileOutlined /> {conductorSeleccionado.phone}</Paragraph>
                </Col>
                <Col md={8} xs={6} style={{ textAlign: "right" }}>
                <Avatar
                    src={conductorSeleccionado.imagen_perfil}
                    size={{ xs: 80, sm: 70, md: 30, lg: 64, xl: 80, xxl: 100 }}
                    icon={<AntDesignOutlined />}
                />  
                </Col>
            </Row>
            {
                conductorSeleccionado.informe ? mostrarResumenInforme(conductorSeleccionado.informe) : false
            }
            <Form layout="vertical" className="mt-3">
                            <Row>
                                <Col md={12}>
                                    <Form.Item label="Fecha de revisión"  className="mb-0">
                                    <DatePicker format="YYYY-MM-DD" locale={locale} defaultValue={dayjs(fechaConsulta, 'YYYY-MM-DD')} onChange={handleChangeDate} />
                                    </Form.Item>
                                </Col>
                                <Col md={12}>
                                    <Form.Item label="Click para buscar" className="mb-0" >
                                        <ButtonGroup>
                                    <Button size="medium" loading={loadingCoordenadas || loadingConductores} icon={<SearchOutlined />} onClick={() => obtenerConductores(1, false, conductorSeleccionado._id)} type="primary">ACTUALIZAR</Button>
                                        </ButtonGroup>
                                    </Form.Item>
                                </Col>
                            </Row>
                        
                    </Form>
        </Card>
    }

    const formatTime = (minutes) => {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = Math.round(minutes % 60);

        if (hours === 0 && remainingMinutes === 0) {
            return "Sin datos";
        } else if (hours === 0 && remainingMinutes < 1) {
            return "Menos de 1 Min.";
        } else if (hours === 0) {
            return `${remainingMinutes} Min.`;
        } else if (remainingMinutes === 0) {
            return `${hours} Hrs.`;
        } else {
            return `${hours} Hrs. ${remainingMinutes} Min.`;
        }
      }
    const obtenerDatoInforme = (inf, key) => {
        if(!inf) return false
        if(!key) return false
        if(typeof inf !== "object") return false
        if(typeof inf[key] === "undefined") return false
        return inf[key]
    }

    const mostrarRegistros = () => {
        if(loadingConductores) return <CargandoTabla />


        return <div>
            {header()}
            <Col md={6}>
                <Card size="small" className="mb-3">
                    <Paragraph level={3} style={{ fontSize: 12 }} className="m-0">Total de conductores filtrados</Paragraph>
                    <Title className="m-0">{total}</Title>
                </Card>
            </Col>
            {mostrarExportador()}
            {buscador()}
            {paginacion(data.pagina, total)}
            <Table size={tableSize ? tableSize : "middle"} dataSource={conductores} pagination={false} scroll={true} >
                <Column title="Perfil" render={(data) => {
                    return <div>
                        <Avatar shape="circle" style={{ color: '#f5222d' }} src={data.imagen_perfil} icon={<UserOutlined />} />
                    </div>
                }} />

                <Column title="Nombre y Apellidos" render={(data) => {
                    return <div>
                        <Tooltip title={titulo_tooltip}>
                        <div>{showByType(typeView, data)} <a target='_blank' href={`${rutas.drivers.slug}/${data._id}`}><BsBoxArrowInUpRight /></a></div>
                        </Tooltip>
                        </div>
                }} />
                <Column title="Fecha" render={(data) => {
                    let fecha = obtenerDatoInforme(data.informe, "fecha")
                    return <div>{fecha}</div>
                }} />
                <Column title="Tiempo conexión" render={(data) => {
                    let numero = obtenerDatoInforme(data.informe, "minutos_conexion")
                    return <div>{formatTime(numero)}</div>
                }} />
                <Column title="Paradas" render={(data) => {
                    let paradas = obtenerDatoInforme(data.informe, "paradas")
                    let steps = 'Sin datos'
                    if(paradas) if(Array.isArray(paradas) !== false) steps = paradas.length
                    return <div>{steps}</div>
                }} />
                <Column title="Distancia recorrida" render={(data) => {
                    let km = obtenerDatoInforme(data.informe, "distancia_km_recorrida")
                    if(!isNaN(parseFloat(km))) km = parseFloat(km).toFixed(2)
                    let validar_distancia = km ? km : 0
                    if(validar_distancia < 1 && validar_distancia > 0 ) validar_distancia = "Menos de 1"
                    return <div>{validar_distancia === 0 ? "Sin datos" : `${validar_distancia} Km.`} </div>
                }} />
            </Table>
        </div>
    }

    return <div>
                    {mostrarSeleccionado()}

                    <Button type="primary" onClick={showDrawer} icon={<FaUserAlt />} >CONDUCTORES</Button>
                    <Drawer title="VER LISTADO" onClose={onClose} open={open} size="large" extra={
                    <Space>
                        <Button onClick={onClose}>CANCELAR</Button>
                        {/* <Button loading={loading} onClick={() => crearRegistro()} type="primary"  >GUARDAR</Button> */}
                    </Space>
                    } >
                        <div style={{ paddingLeft: 20, paddingTop:10, paddingRight: 20 }} className='mt-3'>
                        <Title level={3} className='m-0'>Conductores</Title>
                                <Paragraph>Aquí podrás ver los conductores de tu flota y el estado de conexión</Paragraph>
                                {mostrarRegistros()}
                        </div>
                    </Drawer>
    </div>
    return <div>
        {contextHolder}
        {mostrarRegistros()}
    </div>
}
export default ListadoConductoresRastreo