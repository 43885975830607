const { DateTime } = require("luxon")
const { configuracion_fechas } = require("./dates")
const { timezone } = require("../backend/data")
const configuracion_fechas_cortas = { month: 'short', day: 'numeric', year: 'numeric' }

const control = {}

control.formatYMD = (fecha) => {
  let date = false
  if(fecha){
      if(typeof fecha === "string"){
          date = fecha
      } else {
          date = fecha.toISOString()
      }
  } else {
      date = DateTime.now().setZone(timezone).toJSDate().toISOString()
  }
  const fecha_sin_ofset = date
  const parseado = DateTime.fromISO(fecha_sin_ofset).toFormat("yyyy-MM-dd")
  return parseado
}

control.formatYMD_UTC = (fecha = String) => {
  if(!fecha) return null
  if(typeof fecha !== "string") return null

  const parseado = DateTime.fromISO(fecha).toUTC().toFormat("yyyy-MM-dd")  
  return parseado
}

const formatearMinutos = (value) => {
  if(!isNaN(parseInt(value))){
    if(parseInt(value) < 10) return `0${value}`
  }
  return value
}

control.fechaUTCATexto = (fecha, lang) => {
    if(!fecha) return ''
    const date = new Date(fecha)
    const date2 = DateTime.fromISO(fecha)
    return `${date.toLocaleDateString('es-ES', configuracion_fechas )} · ${date2.hour}:${formatearMinutos(date2.minute)} Hrs.`
}

control.fechaATextoSimpleExperimental = (fecha, lang) => {
  if(!fecha) return ''
  const date2 = DateTime.fromISO(fecha).toJSDate()
  return `${date2.toLocaleDateString('es-ES', configuracion_fechas )}`
}

control.fechaUTCATextoSimple = (fecha, lang) => {
    if(!fecha) return ''
    const date = new Date(fecha)
    const date2 = DateTime.fromISO(fecha).toJSDate()
    return `${date2.toLocaleDateString('es-ES', configuracion_fechas )}`
}

const agregarCero = (value) => {
  if(value < 10) return `0${value}`
  return value
}

control.fechaUTCATiempo = (fecha, lang) => {
    if(!fecha) return ''
    const date = new Date(fecha)
    const date2 = DateTime.fromISO(fecha)
    return `${date2.hour}:${agregarCero(date2.minute)} Hrs.`
}

control.fechaATexto = (fecha, lang) => {
    if(!fecha) return ''
    const date = new Date(fecha)
    const date2 = DateTime.fromISO(fecha.substring(0,23))
    return `${date.toLocaleDateString('es-ES', configuracion_fechas )} · ${agregarCero(date2.hour)}:${agregarCero(date2.minute)} Hrs.`
}

control.fechaAHora = (fecha, lang) => {
    if(!fecha) return ''
    const date2 = DateTime.fromISO(fecha.substring(0,23))
    return `${date2.hour}:${date2.minute} Hrs.`
}

control.fechaATextoSimple = (fecha, lang) => {
    const date2 = DateTime.fromISO(fecha.substring(0,23)).toJSDate()
    const date = new Date(date2)
    return `${date2.toLocaleDateString('es-ES', configuracion_fechas )}`
}

control.fechaATextoExperimental = (fecha, lang) => {
  if(!fecha) return ''
  const date2 = DateTime.fromISO(fecha)
  return `${date2.toJSDate().toLocaleDateString('es-ES', configuracion_fechas )} · ${agregarCero(date2.hour)}:${agregarCero(date2.minute)} Hrs.`
}

control.validateEmail = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

control.ordenarProductos = (registros) => {
  const nuevos = registros.sort((a,b)=> {
    const posicion = typeof a.orden !== 'undefined' ? a.orden : 99999999
    return posicion > b.orden ? 1 : -1
  })
  return nuevos
}

control.limpiarRut = (rut) => {
    if(!rut) return rut
    const dato = rut.toString().replace('.','')
    if(!dato) return rut
    const dato2 = dato.replace('.','')
    if(!dato2) return dato
    const dato3 = dato2.replace('.','')
    if(!dato3) return dato2
    return dato3
}

control.checkForDuplicatesEmails = (email,lista) => {
    const buscar = lista.filter(user => user.email === email)
    if(buscar.length > 0) return true
    return false
}

control.sliceIntoChunks = (arr, chunkSize) => {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
}

control.corregirTelefono = (num) => {

    if(!num){
      return false
    }

    const digito = num.toString().replace(/\s/g, '')

    const longitud = digito.length

    if(longitud === 0){
      return false
    }
    const numero = digito.replace('+56', '56')
    if(longitud === 9){
      return `56${numero}`
    } else if(longitud === 8){
      return `569${numero}`
    }

    return numero

}

control.calcularPorcentaje = (cantidad_total, cantidad) => {
  const porcentaje = (cantidad / cantidad_total) * 100;
  return porcentaje >= 0 ? Math.round(porcentaje) : 0;
};

control.limpiarStringLetrasNumeros = (cadena) => {
  const regex = /[^a-zA-Z0-9]/g; // Expresión regular para buscar caracteres no alfanuméricos
  return cadena.replace(regex, '');
};

control.limpiarStringNumeros = (cadena) => {
  const regex = /\D/g
  return cadena.replace(regex, '');
};

control.siEsRequeridoEnEsquema = (key, esquema) => {
  if(esquema.findIndex(e => e.value === key) > -1) return true
  return false
}

control.duracionTiempoString = (minutos) => {
  const hours = Math.floor(minutos / 60);
  const remainingMinutes = minutos % 60;
  if(!hours) return `${remainingMinutes.toFixed(0)} Mins.`

  return `${hours} Hrs. ${remainingMinutes.toFixed(0)} Mins.`
}

control.filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

control.calcularPesoVolumetrico = (productos) => {
  let default_weight = 0
  let default_vol = 0

  if(!productos) return {
      default_weight,
      default_vol
  }
  if(!Array.isArray(productos)) return {
      default_weight,
      default_vol
  }

  for( const pro of productos ){
      let ancho       = 0
      let alto        = 0
      let largo       = 0
      let peso        = 0
      let cantidad    = 1

      if(!isNaN( parseFloat(pro.ancho) ))     ancho   = parseFloat(pro.ancho)
      if(!isNaN( parseFloat(pro.alto) ))      alto    = parseFloat(pro.alto)
      if(!isNaN( parseFloat(pro.largo) ))     largo   = parseFloat(pro.largo)
      if(!isNaN( parseFloat(pro.peso) ))      peso    = parseInt(pro.peso)
      if(!isNaN( parseInt(pro.quantity) ))    cantidad= parseInt(pro.quantity)

      const total = (ancho * alto * largo) / 1000000
      default_vol = (default_vol + (total * cantidad))
      default_weight = (default_weight + (peso * cantidad))
  }

  const peso_vol = Math.max((default_vol / 250), default_weight)

  return {
      default_weight: peso_vol,
      default_vol
  }
}

control.calcularPesoVolumetricoUnitario = (pro) => {
      let default_weight = 0
      let default_vol = 0
      let ancho       = 0
      let alto        = 0
      let largo       = 0
      let peso        = 0
      let cantidad    = 1

      if(!isNaN( parseFloat(pro.ancho) ))     ancho   = parseFloat(pro.ancho)
      if(!isNaN( parseFloat(pro.alto) ))      alto    = parseFloat(pro.alto)
      if(!isNaN( parseFloat(pro.largo) ))     largo   = parseFloat(pro.largo)
      if(!isNaN( parseFloat(pro.peso) ))      peso    = parseInt(pro.peso)

      const total = (ancho * alto * largo) / 1000000
      default_vol = (default_vol + total) * cantidad
      default_weight = (default_weight + peso) * cantidad
      const peso_vol = Math.max((default_vol / 250), default_weight)

  return {
      weight: peso_vol,
      vol: default_vol
  }
}

control.fechaATextoSimpleCorto = (fecha, lang) => {
  if(!fecha) return ''
  if(typeof fecha !== "string") fecha = DateTime.fromJSDate(fecha).toISO()
  const date2 = DateTime.fromISO(fecha.substring(0,23)).toJSDate()
  const date = new Date(date2)
  return `${date2.toLocaleDateString('es-ES', configuracion_fechas_cortas )}`
}


module.exports = control