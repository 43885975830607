import React, { useSelector } from 'react-redux';
import { Route, Navigate, Routes } from 'react-router-dom';
import Login from '../Login';
import Home from '../Home';
import CompletarRegistro from '../Enrolamiento/completar_registro_empresa';
import ValidarEmpresa from '../Enrolamiento/validacion_empresa';
import Registrarme from '../Enrolamiento/registro';
import Monitoreo from '../Monitoreo';
import Usuarios from '../Usuarios';
import CrearUsuario from '../Usuarios/crear';
import DetallesUsuario from '../Usuarios/detalles';
import Conductores from '../Conductores';
import DetallesConductor from '../Conductores/detalles';
import Vehiculos from '../Vehiculos';
import { rutas } from '../../lib/routes/routes';
import CrearConductor from '../Conductores/crear';
import CrearVehiculo from '../Vehiculos/crear';
import DetallesVehiculo from '../Vehiculos/detalles';
import ImportadorConductores from '../Conductores/importador';
import ImportadorVehiculos from '../Vehiculos/importador';
import Empresa from '../Empresa';
import RadioOperacion from '../RadioOperacion';
import CamposPersonalizados from '../CamposPersonalizados';
import MantenedorGeocerca from '../RadioOperacion/Geogercas/creador';
import Zonas from '../Zonas';
import DetallesZona from '../Zonas/detalles';
import Alertas from '../GeoAlertas';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';    
import Notificaciones from '../Notificaciones';
import AuditoriaConductor from '../AuditoriaConductor';
import EstadisticaTrackingConductor from '../Estadistica/tracking';
import TerminosCondiciones from '../Enrolamiento/terminos_condiciones';
import OlvideContrasena from '../OlvideContrasena';
import CambiarPassword from '../OlvideContrasena/cambiar_password';
import GeoAlertasMapa from '../GeoAlertas/geo_alertas_mapa';
import CategoriasEquipo from '../CategoriasEquipo';
import CategoriasCliente from '../CategoriasCliente';
import SubCategoriasEquipo from '../SubCategoriasEquipo';
import SubCategoriasCliente from '../SubCategoriasCliente';
import Equipos from '../Equipos';
import CrearEquipo from '../Equipos/crear';
import DetallesEquipo from '../Equipos/detalles';
import Clientes from '../Clientes';
import CrearCliente from '../Clientes/crear';
import DetallesCliente from '../Clientes/detalles';
import Contactos from '../Contactos';
import DetallesContacto from '../Contactos/detalles';
import Viajes from '../Viajes';
import CrearViaje from '../Viajes/crear';
import CategoriasCargo from '../CategoriasCargo';
import ImportadorEquipos from '../Equipos/importador';
import ImportadorClientes from '../Clientes/importador';
import Bodegas from '../Bodegas';
import ImportadorViajes from '../Viajes/importador';
import Rutas from '../Rutas';
import DetallesRuta from '../Rutas/detalles';
import Productos from '../Productos';
import EstadosCarga from '../EstadosCarga';
import CategoriasServicios from '../CategoriasServicios';
import CategoriasProductos from '../ CategoriasProductos';
import SubCategoriasProductos from '../SubCategoriasProductos';
import DetallesProducto from '../Productos/detalles';
import ImportadorProductos from '../Productos/importador';
import CategoriasCostos from '../CategoriasCostos';
import SubCategoriasCostos from '../SubCategoriasCostos';
import Costos from '../Costos';
import { Chart, LineController, LineElement, PointElement, BarElement, LinearScale, Tooltip, Title,CategoryScale, ArcElement, Legend } from 'chart.js';
import ViajesReporte from '../Viajes/reporte_viajes';
import DetallesRuteo from '../Ruteo/detalles';
import EstadosDeCarga from '../EstadosCarga/index_js';
import LineaTiempo from '../LineaTiempo';
import Eta from '../Configuracion/eta';
import Enlaces from '../EnlacesRuta';
import Disponibilidades from '../Disponibilidades';
import BusquedaRecursos from '../BusquedaRecursos';
import SolicitudesDisponibilidades from '../SolicitudesDisponibilidades';
import SolicitudesDisponibilidadesExternas from '../SolicitudesDisponibilidadesExternas';
import Personalizar from '../../subComponents/personalizar/personalizar';
import Canales from '../Canales';
import ExpedicionesControl from '../Expeciciones';
import MonitoreoVehiculos from '../Monitoreo/monitoreo_vehiculos';
import Formularios from '../Formularios';
import FormularioLlenadoPublico from '../Formularios/public_fill';
import MonitoreoGeneral from '../Monitoreo/monitoreo';
import ActividadConductores from '../../subComponents/conductores/actividad';
import ConfiguracionTorreControl from '../../subComponents/estados_carga/configuracion_torre_control';
import ListadoEstadosCargaTiempoReal from '../../subComponents/actividad/auditoria/listado_tiempo_real';
Chart.register(LineController, LineElement, PointElement, BarElement, Legend, LinearScale, ArcElement, Title, Tooltip, CategoryScale);


const terminos_condiciones = <Route path='/terms-conditions' element={<TerminosCondiciones />} />

const RoutesApp = (props) => {
    const session = useSelector(state => state.miusuario)

    const outside_session = () => {
        return <Routes>
        <Route path="/login" element={<Login/>} />
        <Route path="/register" element={<Registrarme /> } />
        <Route path={rutas.forget_password.slug} element={<OlvideContrasena />} />
        <Route path={rutas.reset_password.slug} element={<CambiarPassword />} />
        <Route path={`/front/user/invitation/formularios/:id`} element={<FormularioLlenadoPublico />} />
        {terminos_condiciones}
        <Route path='*' element={<Navigate to="/login" />} />
    </Routes>
    }

    // USUARIO NO LOGEADO
    if(!session) return outside_session()

    // COMPLETAR DATOS DE EMPRESA
    const ruta_completar_registro = () => {
        return <Routes>
        <Route path='/redirect/business/complete/register' element={<CompletarRegistro />}  />
        <Route path='*' element={<Navigate to="/redirect/business/complete/register" />} />
        {terminos_condiciones}
    </Routes>
    }
    
    // VALIDAR REQUISITOS DE EMPRESA
    const ruta_validar_empresa = () => {
        return <Routes>
        <Route path='/redirect/business/complete/validate' element={<ValidarEmpresa />}  />
        <Route path='*' element={<Navigate to="/redirect/business/complete/validate" />} />
        {terminos_condiciones}
    </Routes>
    }

    // USUARIO LOGEADO - DIVERSOS FLUJOS
    const { data } = session
    if(!data) return outside_session()
    if(!data.empresa) return ruta_completar_registro()
    if(!["active","in_process"].includes(data.empresa.status)) return ruta_validar_empresa()

    return <Routes>
        <Route path={"/"} element={<Home/>} />
        <Route path={rutas.business.slug} element={<Empresa/>} />
        <Route path={rutas.monitoring_general.slug} element={<MonitoreoGeneral />} />
        <Route path={rutas.monitoring.slug} element={<Monitoreo/>} />
        <Route path={rutas.monitoring_vehicles.slug} element={<MonitoreoVehiculos />} />
        <Route path={rutas.vehicles.slug} element={<Vehiculos/>} />
        <Route path={`${rutas.vehicles.slug}/new`} element={<CrearVehiculo/>} />
        <Route path={`${rutas.vehicles.slug}/:id`} element={<DetallesVehiculo />} />
        <Route path={`${rutas.ckeck_list.slug}`} element={<Formularios />} />
        
        <Route path={rutas.rutas.slug} element={<Rutas />} />
        <Route path={`${rutas.rutas.slug}/:id`} element={<DetallesRuta />} />
        <Route path={`${rutas.ruteo.slug}/:id`} element={<DetallesRuteo />} />

        <Route path={rutas.estados_carga.slug} element={<EstadosCarga user={session} />} />
        <Route path={rutas.actividad_flota.slug} element={<EstadosDeCarga />} />

        <Route path={rutas.import_vehicles.slug} element={<ImportadorVehiculos />} />
        <Route path={rutas.import_equipment.slug} element={<ImportadorEquipos />} />
        <Route path={rutas.import_clients.slug} element={<ImportadorClientes />} />
        <Route path={rutas.import_drivers.slug} element={<ImportadorConductores />} />
        <Route path={rutas.import_trips.slug} element={<ImportadorViajes />} />
        <Route path={rutas.import_products.slug} element={<ImportadorProductos />} />

        <Route path={rutas.disponibilidades.slug} element={<Disponibilidades display_full={true} showFilters={true} titulo="Disponibilidades" />} />
        <Route path={rutas.solicitud_disponibilidad.slug} element={<SolicitudesDisponibilidades display_full={true} showFilters={true} titulo="Solicitudes" />} />
        <Route path={rutas.solicitud_disponibilidad_externa.slug} element={<SolicitudesDisponibilidadesExternas display_full={true} showFilters={true} titulo="Solicitudes a mi empresa" />} />

        <Route path={rutas.busqueda_recursos.slug} element={<BusquedaRecursos display_full={true} showFilters={true} titulo="Buscar vehículos" />} />

        <Route path={rutas.costos.slug} element={<Costos showFilters={true} showCustomFieldsData={true} titulo="Costos" display_full={true} />} />
        
        <Route path={rutas.bodega_expediciones.slug} element={<ExpedicionesControl display_full={true} />} />
        <Route path={rutas.torre_control_config.slug} element={<ConfiguracionTorreControl />} />
        <Route path={rutas.auditoria_pedidos.slug} element={<ListadoEstadosCargaTiempoReal />} />

        <Route path={rutas.timeline.slug} element={<LineaTiempo display_full={true} />} />
        <Route path={rutas.eta.slug} element={<Eta display_full={true} />} />


        <Route path={rutas.products.slug} element={<Productos showFilters={true} showCustomFieldsData={true} titulo="Productos" display_full={true} />} />
        <Route path={`${rutas.products.slug}/:id`} element={<DetallesProducto />} />

        <Route path={rutas.drivers.slug} element={<Conductores/>} />
        <Route path={`${rutas.drivers.slug}/new`} element={<CrearConductor/>} />
        <Route path={`${rutas.drivers.slug}/:id`} element={<DetallesConductor />} />
        <Route path={rutas.usuarios.slug} element={<Usuarios/>} />
        <Route path={`${rutas.usuarios.slug}/new`} element={<CrearUsuario/>} />
        <Route path={`${rutas.usuarios.slug}/:id`} element={<DetallesUsuario/>} />
        <Route path={rutas.operation_zones.slug} element={<RadioOperacion/>} />
        <Route path={rutas.tracking_driver.slug} element={<AuditoriaConductor />} />
        <Route path={rutas.zones.slug} element={<Zonas />} />
        <Route path={`${rutas.zones.slug}/new`} element={<MantenedorGeocerca />} />
        <Route path={`${rutas.zones.slug}/:id`} element={<DetallesZona/>} />
        <Route path={rutas.custom_fields.slug} element={<CamposPersonalizados/>} />
        <Route path={rutas.alerts.slug} element={<Alertas />} />
        <Route path={rutas.map_alerts.slug} element={<GeoAlertasMapa />} />
        <Route path={rutas.notifications.slug} element={<Notificaciones />} />
        <Route path={rutas.estadistica_tracking.slug} element={<EstadisticaTrackingConductor />} />
        <Route path={rutas.auditoria_conductores.slug} element={<ActividadConductores />} />

        
        <Route path={rutas.products_categories.slug}            element={<CategoriasProductos tipo="products" titulo="Categoría de productos" />} />
        <Route path={rutas.products_subcategories.slug}         element={<SubCategoriasProductos tipo="products" titulo="Sub Categorías de productos" />} />
        <Route path={rutas.cargo_categories.slug}               element={<CategoriasCargo tipo="cargo" titulo="Categoría de cargos" />} />
        <Route path={rutas.clients_categories.slug}             element={<CategoriasCliente tipo="cliente" titulo="Categoría de clientes" />} />
        <Route path={rutas.clients_sub_categories.slug}         element={<SubCategoriasCliente tipo="cliente" titulo="Sub Categorías de clientes" />} />
        <Route path={rutas.equipment_categories.slug}           element={<CategoriasEquipo tipo="equipo" titulo="Categoría de equipos" />} />
        <Route path={rutas.equipment_sub_categories.slug}       element={<SubCategoriasEquipo tipo="equipo" titulo="Sub Categorías de equipos" />} />
        <Route path={rutas.costos_categories.slug}              element={<CategoriasCostos tipo="costos" titulo="Categorías de costos" />} />
        <Route path={rutas.costos_sub_categories.slug}       element={<SubCategoriasCostos tipo="costos" titulo="Sub Categorías de costos" />} />

        <Route path={rutas.costos_categories.slug}              element={<CategoriasCostos tipo="costos" titulo="Categorías de costos" />} />

        <Route path={rutas.tipos_servicio.slug} element={<CategoriasServicios tipo="servicios" titulo="Tipos de servicio" />} />
        
        <Route path={rutas.warehouses.slug} element={<Bodegas display_full={true} />} />
        <Route path={rutas.enlaces.slug} element={<Enlaces display_full={true} />} />
        <Route path={rutas.clients.slug} element={<Clientes />} />
        <Route path={`${rutas.clients.slug}/new`} element={<CrearCliente />} />
        <Route path={`${rutas.clients.slug}/:id`} element={<DetallesCliente />} />
        <Route path={rutas.contacts.slug} element={<Contactos showFilters={true} showCustomFieldsData={true} titulo="Contactos" display_full={true} />} />
        <Route path={`${rutas.contacts.slug}/:id`} element={<DetallesContacto />} />
        <Route path={rutas.equipment.slug} element={<Equipos />} />
        <Route path={`${rutas.equipment.slug}/:id`} element={<DetallesEquipo/>} />
        <Route path={`${rutas.equipment.slug}/new`} element={<CrearEquipo />} />

        <Route path={`${rutas.canales.slug}`} element={<Canales showFilters={true} showCustomFieldsData={true} titulo="Canales" display_full={true} />} />
        
        <Route path={rutas.trips.slug} element={<Viajes />} />
        <Route path={rutas.personalizar.slug} element={<Personalizar />} />
        <Route path={rutas.trips_report.slug} element={<ViajesReporte />} />

        <Route path={`${rutas.trips.slug}/new/:id`} element={<CrearViaje />} />
        {terminos_condiciones}
        <Route path='*' element={<Navigate to="/" />} />
    </Routes>
}

export default RoutesApp;