import { useDispatch, useSelector } from "react-redux"
import data, { limit_page_options, provider_event_types, urlapi } from "../../lib/backend/data"
import { useEffect, useRef, useState } from "react"
import { cerrarSesion } from "../../redux/actions/sesion"
import { Alert, Badge, Button, Card, Checkbox, Col, Divider, Form, Input, Layout, Pagination, Row, Select, Table, Tag, message } from "antd"
import CargandoTabla from "./cargando"
import Column from "antd/es/table/Column"
import Title from "antd/es/typography/Title"
import Paragraph from "antd/es/typography/Paragraph"
import { fechaATexto, fechaATextoSimple, fechaUTCATexto } from "../../lib/helpers/helpers"
import { Link } from "react-router-dom"
import { FileExcelFilled, PlusCircleFilled, SearchOutlined } from "@ant-design/icons";
import { obtenerFechaHoraZonaHorariaLocal } from "../../lib/helpers/dates"
import ContadoresVehiculos from "./contadores"
import SelectorTipo from "./selectores/tipo_vehiculo"
import SelectorTipoMotor from "./selectores/tipo_motor"
import SelectorMarcas from "./selectores/marca"
import SelectorModelo from "./selectores/modelo"
import SelectorColor from "./selectores/color"
import { IoFilterSharp } from "react-icons/io5"
import GraficosCamposPersonalizados from "../../subComponents/graficos/conductores"
import ModalPersonalizado from "../../subComponents/general/modales/modal_personalizado"
import { encontrarLlaveValorEnEsquema } from "../../lib/helpers/interfaz"
import PersonalizarColumnas from "../../subComponents/interfaz/columnas_personalizar"
import EncabezadoPesonalizado from "../../subComponents/general/tablas/encabezado"
import HelperModal from "../../subComponents/general/helperModal"
import { rutas } from "../../lib/routes/routes"
import { calcularVolumen, formatoMoneda, obtenerLimitesPagina, stringByStatus } from "../../lib/helpers/main"
import { stringByPalletType } from "./data"
import { estilo_moving_truck } from "../../lib/estilo_sitio"
import TagSinDatos from "../../subComponents/general/tag_sin_datos"
import ListadoNotificaciones from "../Notificaciones/listado"
import SortBy from "../../subComponents/general/sort_by"

const ListadoVehiculos = (props)=> {
    const {
        condicion_default,
        showFilters,
        showCustomFieldsData,
        showCounters,
        exclude,
        hideSearch,
        showSelection,
        typeView,
        componentAction,
        loadingActions,
        stopRefresh,
        extra_columns
    } = props
    const dispatch = useDispatch()
    const columnas_extra = Array.isArray(extra_columns) ? extra_columns : []
    const sesion = useSelector(state => state.miusuario)
    const [ conductores, setRegistros] = useState([])
    const [ loadingRegistros, setLoadingRegistros] = useState([])
    const [ openFilters, setOpenFilters ] = useState(false)
    const [ loadingExcel, setLoadingExcel ] = useState(false)
    const [ messageApi, contextHolder] = message.useMessage();
    const [ pagina, setPagina ] = useState(1)
    const [ campoBusqueda, setCampoBusqueda ] = useState('')
    const [ total, setTotal ] = useState(0)
    const [ isMounted, setIsMounted ] = useState(false)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(condicion_default ? condicion_default : {})
    const trash = props.trash ? props.trash : false
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const tipo = props.tipo ? props.tipo : "link"
    const [ filtroBusqueda, setFiltroBusqueda ] = useState({})
    const titulo = props.title ? props.title : 'registros'
    const interfaz_usuario          = useSelector(state => state.interfaz)
    const key_interfaz              = 'tabla_vehiculos'
    const key_esquema               = 'VEHICULOS'
    const [ key_input_filter, setKeyInputFilter ] = useState(0)
    const modulo = 'vehiculos'
    const default_limit_page                = obtenerLimitesPagina(limit_page_options, 'limit_page', interfaz_usuario, modulo)

    const obtenerDatos = async (page, query, sort_config, limit)=>{
        
        setLoadingRegistros(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        return fetch(`${urlapi}/vehiculos/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page,
                columnas_extra,
                sort_config,
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma,
                limit: limit ? limit : default_limit_page
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoadingRegistros(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoadingRegistros(false)
            }
            if(Array.isArray(res.datos) !== false){
                
                setRegistros(res.datos)
                setTotal(res.total)
              
            }
            return setLoadingRegistros(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingRegistros(false)
        })
    }

    const inicializar = () => {
        obtenerDatos(1, false, )
    }

    useEffect(() => {
        if(stopRefresh === true){
            if(!isMounted) inicializar()
        } else {
            inicializar()
        }
        setIsMounted(true)
    }, [ condicion_default ])

    const handleChangePagina = (e) => {
        setPagina(e)
        obtenerDatos(e, condicion_busqueda)
    }
    
    const refrescarBusqueda = () => {
        setPagina(1)
        let cond = {}
        Object.keys(filtroBusqueda).map(llave => {
            if(filtroBusqueda[llave]['$in']){
                if(Array.isArray(filtroBusqueda[llave]['$in']) !== false){
                    if(filtroBusqueda[llave]['$in'].length > 0) cond[llave] = filtroBusqueda[llave]
                }
            }
        })
        obtenerDatos(1, cond)
    }

    const sortBy = (data, type) => {
        if(type === "sort_config"){

            const {
                sort_column,
                sort_order
            } = data
            setPagina(1)
            obtenerDatos(1, null, { sort_column, sort_order })

        } else if( type === "limit_pagination" ){

            setPagina(1)
            obtenerDatos(1, null, null, data.limit_page)            
            
        }
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        if(!cantidad) return false

        const search = buscador()
        const filters_advance = mostrarFiltrosBusqueda()

        return <div className="mb-3">
            <Row gutter={15}>
                {/* <Col span="auto"><PersonalizarColumnas key_interfaz={key_interfaz} key_esquema={key_esquema} /></Col>
                <Col span="auto"><Button loading={loadingExcel} style={{ marginRight: 10, marginBottom: 15 }} color="green"  onClick={() => descargarExcel()}><FileExcelFilled /> EXPORTAR</Button></Col> */}
                { filters_advance ? <Col md={24}>{filters_advance}</Col> : false }
                <Col span="auto"><SortBy type="vehiculos" onChange={(data, type) => sortBy(data, type)} /></Col>
                { search ? <Col md={10}>{search}</Col> : false }
            </Row>

            <Title level={5} className="mt-0" >TOTAL <b style={{ fontWeight: "bold" }}>{total}</b></Title>
            <Pagination current={pagina} total={(cantidad*10)} showSizeChanger={false} onChange={handleChangePagina} />
        </div>
    }

    const descargarExcel = async () => {
        setLoadingExcel(true)
        return fetch(`${data.urlapi}/reports/vehicles`,{
            method:'POST',
            body: JSON.stringify({
                condicion: condicion_busqueda
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.blob()
        })
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            const fecha = obtenerFechaHoraZonaHorariaLocal()
            a.download = `reporte-${fecha}.xlsx`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();
            return setLoadingExcel(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingExcel(false)
        })
    }


    const deseleccionarTodos = (e) => {
        setRegistros([...[], ...conductores.map(veh => { 
            if(exclude){
                if( Array.isArray(exclude) !== false ){
                    if(exclude.includes(veh._id)) return veh
                }
            }
            veh.seleccionado = e.target.checked
            return veh 
        })])
      }

    const handleSubmit = (e) => {
        if(e) e.preventDefault()
        if (!campoBusqueda){
            setPagina(1)
            setCondicionBusqueda({})
            return obtenerDatos(1, {})
        }
        const condicion = {
          $text: { $search: campoBusqueda.toLowerCase() }
        };
        setCondicionBusqueda(condicion);
        setPagina(1);
        return obtenerDatos(1, condicion);
      };

      const handleChangeCampo = (e) => {
        const { value } = e.target;
        return setCampoBusqueda(value);
      };

      const seleccionarRegistro = (data, action, unselect) => {
        if(unselect === true) deseleccionarTodos({ target: false })
        let datos = data
        if(Array.isArray(datos) !== true) datos = [data]
        const no_permitir = Array.isArray(exclude) ? exclude : []
        if(no_permitir.includes(data?._id)) return messageApi.error("No es posible seleccionar este registro")
        if(props.onSelect) props.onSelect(datos, action)
    }

    const seleccionarEspecial = (action) => {
        return seleccionarRegistro(conductores.filter(veh => veh.seleccionado === true), action, true)
      }

      const showByType = (tipo, data) => {

        let bloqueado = false
        const no_permitir = Array.isArray(exclude) ? exclude : []
        if(no_permitir.includes(data?._id)) bloqueado = true
        const valor = data.patente ? data.patente : "SIN INFORMACIÓN"
        if(bloqueado === true) return <b style={{ textTransform: "uppercase", color: "gray" }}>{valor}</b>
        switch (tipo) {
            case "funcion":
                return <b className="hover" onClick={() => seleccionarRegistro(data)} style={{ textTransform: "uppercase" }}>{data.patente}</b>
            default:
                return <Link to={`${rutas.vehicles.slug}/${data._id}`} style={{ textTransform: "uppercase" }}>{data.patente}</Link>
        }
    }

      
    const buscador = () => {
        if(hideSearch === true) return false
        return <Form onSubmitCapture={handleSubmit} >
        {/* <Title className="mb-0 mt-0" level={5}>BUSCAR</Title> */}
        <Row gutter={10}>
            <Col md={18}>
                <Form.Item className="mb-2">
                    <Input key={key_input_filter} allowClear defaultValue={campoBusqueda} onChange={handleChangeCampo} placeholder={`Patente, chasis, número de motor`} />
                </Form.Item>
            </Col>
            <Col md={6}>
                <Form.Item >
                    <Button  onClick={handleSubmit} style={{ width: "100%"}}><SearchOutlined /> BUSCAR</Button>
                </Form.Item>
            </Col>
        </Row>
                </Form>
      }

      const accionButtons = () => {
        if(showSelection !== true) return false
        const seleccionados = conductores.filter(veh => veh.seleccionado === true).length
        const enable = seleccionados > 0 ? false : true
        
        if(componentAction){
            return <div style={{ marginTop: 10, marginBottom: 10 }} onClick={() => seleccionarRegistro(conductores.filter(veh => veh.seleccionado === true))}>
                {componentAction.map(it => {
                    return <Button disabled={enable} loading={ loadingActions === true } size="small" style={{ marginRight: 10 }} onClick={() => seleccionarEspecial(it.label)} >{it.label}</Button>
                })}
            </div>
        }

        return <div style={{ marginTop: 10, marginBottom: 10 }}>
            <Button disabled={enable} size="small" onClick={() => seleccionarRegistro(conductores.filter(veh => veh.seleccionado === true))}>SELECCIONAR</Button>
        </div>
      }

      const checkForEnabledValue = (id) => {
        if(exclude){
            if( Array.isArray(exclude) !== false ){
                if(exclude.includes(id)) return true
            }
        }
        return false
      }

      const validarChecked = (seleccionado, id) => {
        if(seleccionado === true) return true
        if(exclude){
            if( Array.isArray(exclude) !== false ){
                if(exclude.includes(id)) return true
            }
        }
        return false
      }

      const handleChangeSeleccion = (e) => {
        const { name } = e.target
        const valor = e.target.checked
        setRegistros(prev => {
            let actual = [...prev]
            const i = actual.findIndex(ve => ve._id === name )
            actual[i].seleccionado = valor
            return [...[], ...actual]
        })
      }

      const mostrarTabla = () => {
        if(loadingRegistros) return <CargandoTabla />

        const component_patente = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="patente" />} render={(data) => {
            return <div>
                {showByType(typeView, data)}
                {data.selected === true ? <Tag color="blue" >ASIGNADO</Tag> : ""}
            </div>
            }} />

        const component_conductor = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="conductor" />} render={(data) => {
            return <div style={{ textTransform: "uppercase" }} >{data.conductor?.nombres} {data.conductor?.apellido_p} {data.conductor?.apellido_m}</div>
        }} />

        const component_modelo = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="modelo" />} render={(data) => {
            return <div>{data.modelo ? data.modelo.toUpperCase() : "SIN INFORMACIÓN"}</div>
        }} />

        const component_marca = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="marca" />} render={(data) => {
            return <div>{data.marca ? data.marca.toUpperCase() : "SIN INFORMACIÓN"}</div>
        }} />

        const component_ano = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="ano" />} render={(data) => {
            return <div>{data.ano}</div>
        }} />

        const component_tipo = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="tipo" />} render={(data) => {
            return <div>{data.tipo ? data.tipo.toUpperCase() : "SIN INFORMACIÓN"}</div>
        }} />
        
        const component_fecha = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="createdAt" />} render={(data) => {
            return <div>{fechaUTCATexto(data.createdAt)}</div>
        }} />
        
        const component_tipo_motor = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="tipo_motor" />} render={(data) => {
            return <div>{data.detalle_tipo_motor ? data.detalle_tipo_motor : "SIN INFORMACIÓN"}</div>
        }} />
        const component_volumen = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="volumen" />} render={(data) => {
            const vol = calcularVolumen(data, data.unidad_medida)
            return <div><Tag><b style={{ fontWeight: "bold" }}>{vol.unidadVolumen}</b> {vol.volumen.toFixed(2)}</Tag></div>
        }} />
        const component_peso = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="peso" />} render={(data) => {
            return <div><Tag><b style={{ fontWeight: "bold" }}>{data.unidad_medida_peso}</b> {formatoMoneda(data.peso)}</Tag> </div>
        }} />

        const component_capacidad = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="capacidad" />} render={(data) => {
            return <div>
                { data.ancho ? <Tag>ANCHO <b style={{ fontWeight: "bold" }}>{data.unidad_medida}</b> {data.ancho}</Tag> : false }
                { data.alto ? <Tag>ALTO <b style={{ fontWeight: "bold" }}>{data.unidad_medida}</b> {data.alto}</Tag> : false }
                { data.largo ? <Tag>LARGO <b style={{ fontWeight: "bold" }}>{data.unidad_medida}</b> {data.largo}</Tag> : false }
                </div>
        }} />
        const component_creador = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="creador" />} render={(data) => {
            return <div style={{ textTransform: "uppercase" }}>{data.creador ? data.creador?.nombres : "Sin información"}</div>
        }} />
        const component_status = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="status" />} render={(data) => {
            return <div>{stringByStatus(data.status)}</div>
        }} />
        
        const component_tipo_pallet = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="tipo_pallet" />} render={(data) => {
            return <div>{stringByPalletType(data.tipo_pallet)}</div>
        }} />

        const component_cantidad_pallet = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="cantidad_pallet" />} render={(data) => {
            return <div>{data.cantidad_pallet}</div>
        }} />
        
        const component_vehiculos = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="vehiculos" />} render={(data) => {
            const component = <ContadoresVehiculos onFilter={(condition) => {
                setPagina(1)
                setCondicionBusqueda(condition)
                obtenerDatos(1, condition)
            }} />
    
            return <ModalPersonalizado typeView="modal" slug="distrubion" component={component} /> 

        }} />

        return <Table dataSource={conductores} pagination={false} scroll={estilo_moving_truck.scroll_row} locale={{ emptyText: <HelperModal tipo="vehiculos" /> }}>
            {
            showSelection !== true ? false : <Column title={<Checkbox onChange={deseleccionarTodos} />} render={(data) => {
                return <Checkbox disabled={checkForEnabledValue(data._id)} checked={validarChecked(data.seleccionado, data._id)} name={data._id} onChange={handleChangeSeleccion}/>
            }} />
        }
        { encontrarLlaveValorEnEsquema("patente", interfaz_usuario[ key_interfaz ]) === false ? false : component_patente }
        { encontrarLlaveValorEnEsquema("conductor", interfaz_usuario[ key_interfaz ]) === false ? false : component_conductor }
        { encontrarLlaveValorEnEsquema("vehiculos", interfaz_usuario[ key_interfaz ]) === false ? false : component_vehiculos }
        { encontrarLlaveValorEnEsquema("modelo", interfaz_usuario[ key_interfaz ]) === false ? false : component_modelo }
        { encontrarLlaveValorEnEsquema("marca", interfaz_usuario[ key_interfaz ]) === false ? false : component_marca }
        { encontrarLlaveValorEnEsquema("ano", interfaz_usuario[ key_interfaz ]) === false ? false : component_ano }
        { encontrarLlaveValorEnEsquema("tipo", interfaz_usuario[ key_interfaz ]) === false ? false : component_tipo }
        { encontrarLlaveValorEnEsquema("tipo_pallet", interfaz_usuario[ key_interfaz ]) === false ? false : component_tipo_pallet }
        { encontrarLlaveValorEnEsquema("cantidad_pallet", interfaz_usuario[ key_interfaz ]) === false ? false : component_cantidad_pallet }
        { encontrarLlaveValorEnEsquema("createdAt", interfaz_usuario[ key_interfaz ]) === false ? false : component_fecha }
        { encontrarLlaveValorEnEsquema("tipo_motor", interfaz_usuario[ key_interfaz ]) === false ? false : component_tipo_motor }
        { encontrarLlaveValorEnEsquema("volumen", interfaz_usuario[ key_interfaz ]) === false ? false : component_volumen }
        { encontrarLlaveValorEnEsquema("peso", interfaz_usuario[ key_interfaz ]) === false ? false : component_peso }
        { encontrarLlaveValorEnEsquema("capacidad", interfaz_usuario[ key_interfaz ]) === false ? false : component_capacidad }
        { encontrarLlaveValorEnEsquema("creador", interfaz_usuario[ key_interfaz ]) === false ? false : component_creador }
        { encontrarLlaveValorEnEsquema("status", interfaz_usuario[ key_interfaz ]) === false ? false : component_status }
        {
            columnas_extra.map(key => {
                if(key === "empresa") return <Column title="Empresa" render={(data) => {
                    return <div>{data.empresa?.razon_social}</div>
                }} />

                if(key === "desasignacion") return <Column title="Última Desasignación" render={(data) => {
                    let dato = data?.desasignacion
                    return <div>{dato ? <div>
                        <p style={{ fontSize: 11 }} className="mb-0">{dato.mensaje}</p>
                        <Tag>{fechaATexto(dato.createdAt)}</Tag>
                    </div> : <TagSinDatos /> }</div>
                } } />
                
                if(key === "asignacion") return <Column title="Última Asignación" render={(data) => {
                    let dato = data?.asignacion
                    return <div>{dato ? <div>
                        <p style={{ fontSize: 11 }} className="mb-0">{dato.mensaje}</p>
                        <Tag>{fechaATexto(dato.createdAt)}</Tag>
                        <div style={{ marginTop: 10 }}><ModalPersonalizado version="v2" title="VER HISTÓRICO" typeView="modal" slug="asignacion" component={<ListadoNotificaciones hideFilters={true} condicion_default={{ event_type: { $in: [ provider_event_types.VEHICULOS.VEHICLE_ASIGNED, provider_event_types.VEHICULOS.VEHICLE_UNASIGNED ] }, id_vehicle: data._id }} />} /></div>
                    </div> : <TagSinDatos /> }</div>
                } } />
            })
        }
      </Table>
      }

      const agregarFiltro = (key, value) => {
        if(value.length < 1){
            delete filtroBusqueda[key]
        } else {
            filtroBusqueda[key] = { $in: value.map(v => v._id) }
        }
        setFiltroBusqueda(filtroBusqueda)
      }

      const resetFilters = () => {
        setKeyInputFilter(key_input_filter + 1)
        setFiltroBusqueda({})
        obtenerDatos(1, condicion_default ? condicion_default : {})
        setCampoBusqueda('')
    }

      const mostrarFiltrosBusqueda = () => {
        if(!openFilters) return false
        let mostrarButtonReset = Object.keys(filtroBusqueda).length > 0 ? true : false
        if(campoBusqueda) mostrarButtonReset = true
        return <div style={{ marginBottom: 20 }} className="mt-0">
            <Row gutter={10}>
                <Col md={6} className="mb-3">
                    <Title className="mt-0" level={5}>Tipo de vehículo</Title>
                    <SelectorTipo key={key_input_filter} mode="multiple" onChange={(data) => agregarFiltro("id_tipo", data)} placeholder="Tipo de vehículo" />
                </Col>
                <Col md={6} className="mb-3">
                    <Title className="mt-0" level={5}>Tipo de motor</Title>
                    <SelectorTipoMotor key={key_input_filter} mode="multiple" onChange={(data) => agregarFiltro("tipo_motor", data)} placeholder="Tipo de motor" />
                </Col>
                <Col md={6} className="mb-3">
                    <Title className="mt-0" level={5}>Marca</Title>
                    <SelectorMarcas key={key_input_filter} mode="multiple" onChange={(data) => agregarFiltro("marca", data)} placeholder="Marca" />
                </Col>
                <Col md={6} className="mb-3">
                    <Title className="mt-0" level={5}>Modelo</Title>
                    <SelectorModelo key={key_input_filter} mode="multiple" onChange={(data) => agregarFiltro("modelo", data)} placeholder="Modelo" />
                </Col>
                <Col md={6} className="mb-3">
                    <Title className="mt-0" level={5}>Color</Title>
                    <SelectorColor key={key_input_filter} mode="multiple" onChange={(data) => agregarFiltro("color", data)} placeholder="Color" />
                </Col>
                <Col md={6} className="mb-3">
                    <Title className="mt-0" level={5}>Estado</Title>
                    <Select defaultValue={""} style={{ width: "100%" }} options={[{ value: "", label: "Todos"},{ value: "active", label: "Activo"}, { value: "trash", label: "Inactivo" } ]} onChange={(data) => agregarFiltro("status", data ? [ { _id: data } ] : [])} placeholder="Estado" />
                </Col>
                <Col md={4} className="mb-3">
                    <Title className="mt-0" level={5}>Buscar</Title>
                    <Button loading={loadingRegistros} type="primary" onClick={() => refrescarBusqueda()} style={{ width: "100%" }} icon={<SearchOutlined />} >ACTUALIZAR</Button>
                </Col>
                <Col md={4}>
                    <Title className="mb-2 mt-0" level={5}>Limpiar</Title>
                    <Button disabled={!mostrarButtonReset}  style={{ width: "100%" }} onClick={() => resetFilters()}>BORRAR FILTROS</Button>
                </Col>
            </Row>
        </div>
      }

      const mostrarContadores = () => {
        const component = <ContadoresVehiculos onFilter={(condition) => {
            setPagina(1)
            setCondicionBusqueda(condition)
            obtenerDatos(1, condition)
        }} />

        return showCounters === true ? <ModalPersonalizado typeView="modal" slug="distrubion" component={component} /> : false
      }
      
      const mostrarDatosPersonalizados = () => {
        const component = <GraficosCamposPersonalizados typeView="modal" tipo="vehiculo" />
        return showCustomFieldsData === true ? <ModalPersonalizado typeView="modal" slug="informe_personalizado" component={component} /> : false
      }

    const mostrarRegistros = () => {
        const contadores = mostrarContadores()
        const datosPersonalizados = mostrarDatosPersonalizados()
        
        const botones = <Row gutter={15} style={{ marginTop: 20 }}>
        { datosPersonalizados ? <Col span="auto" >{datosPersonalizados}</Col> : false }
        { contadores ? <Col span="auto" >{contadores}</Col> : false }
        <Col span="auto"><Button type={ openFilters ? "primary" : "default" } icon={<IoFilterSharp />} onClick={() => setOpenFilters(!openFilters)} >FILTROS</Button> </Col>
        <Col span="auto"><PersonalizarColumnas key_interfaz={key_interfaz} key_esquema={key_esquema} /></Col>
        <Col span="auto"><Button loading={loadingExcel} style={{ marginRight: 10, marginBottom: 15 }} color="green" onClick={() => descargarExcel()}><FileExcelFilled /> EXPORTAR</Button></Col>
        </Row>

        return <div>
            {
                showFilters === true ? <Row gutter={15}>
                <Col md={24}>

                </Col>
                <Col md={24}>
                    <Row gutter={24} className="mb-3">
                    </Row>
                    {botones}
                    {paginacion(data.pagina, total)}
                    {accionButtons()}
                    {mostrarTabla()}
                </Col>
            </Row> : <div>
                    {botones}
                    {paginacion(data.pagina, total)}
                    {accionButtons()}
                    {mostrarTabla()}
                </div>
            }
            
            
        </div>
    }

    return <div>
        {contextHolder}
        {mostrarRegistros()}
    </div>
}
export default ListadoVehiculos