import Title from "antd/es/typography/Title"
import Structure from "../Structure"
import { Alert, Breadcrumb, Button, Card, Col, Divider, Form, Input, Layout, Popconfirm, Rate, Row, Select, Tabs, Tag, Upload, message } from "antd"
import { CameraOutlined, CarFilled, HomeOutlined, InfoCircleFilled, LoadingOutlined, PlusOutlined, SaveOutlined, UserOutlined } from "@ant-design/icons"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { limpiarStringLetrasNumeros, limpiarStringNumeros, siEsRequeridoEnEsquema, validateEmail } from "../../lib/helpers/helpers"
import { config_s3, urlapi } from "../../lib/backend/data"
import { cerrarSesion } from "../../redux/actions/sesion"
import { rutas } from "../../lib/routes/routes"
import { nombreDNIPorPais } from "../../lib/helpers/data/internationa"
import S3FileUpload from 'react-s3';
import SelectorMarcas from "./selectores/marca"
import SelectorTipoMotor from "./selectores/tipo_motor"
import SelectorTipo from "./selectores/tipo_vehiculo"
import SelectorColor from "./selectores/color"
import SelectorModelo from "./selectores/modelo"
import ModalSeleccionVehiculos from "../Conductores/modal_seleccion"
import CargandoTabla from "./cargando"
import { useParams } from "react-router-dom"
import ListadoRequisitos from "../Requisitos/listado"
import { TbFileCertificate, TbGps, TbTag } from 'react-icons/tb'
import ListadoRequisitosModelos from "../Requisitos/esquema_agregar"
import CamposPersonalizadosRecurso from "../../subComponents/campos_personalizados/listado_recurso"
import { obtenerUnidadesMedidaPorTipoPais } from "../../lib/helpers/hepler_main"
import ModalSeleccionEquipos from "../Equipos/modal_seleccion"
import EquiposRelaciones from "../Equipos/relaciones"
import AgendaRecurso from "../../subComponents/agenda/agenda_recurso"
import { calcularVolumen, convertirAKilogramos, convertirVolumenAMetorCubicios } from "../../lib/helpers/main"
import { tamanosCamion, tipos_pallet } from "./data"
import ListadoCalificaciones from "../Calificaciones/listado"
import CamposPersonalizadosRelacionador from "../../subComponents/general/campos_personalizados_relacionador"

const DetallesVehiculo = (props) => {
    const {
        id
    } = useParams()
    const [ loading, setLoading ] = useState(false)
    const [ loadingMaster, setLoadingMaster ] = useState(true)
    const [ loadingDefinicion, setLoadingDefinicion ] = useState(false)
    const [ loadingImagen, setLoadingImagen ] = useState(false)
    const [ conductor, setConductor ] = useState(false)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [messageApi, contextHolder] = message.useMessage();
    const [ usuario, setUsuario ] = useState(false)
    const dispatch = useDispatch()
    const unidades_medida               = obtenerUnidadesMedidaPorTipoPais("longitud", pais).map(e => e)
    const unidades_medida_peso          = obtenerUnidadesMedidaPorTipoPais("peso", pais).map(e => e)
    const unidades_medida_volumen       = obtenerUnidadesMedidaPorTipoPais("volumen", pais).map(e => e)
    const requeridos = [
        { value: 'patente', label: 'Patente' },
        { value: 'marca', label: 'Marca' },
        { value: 'ano', label: 'Año' },
        { value: 'id_tipo', label: 'Tipo de vehículo' },
      ];

    const obtenerDatos = async () => {
        return fetch(`${urlapi}/vehiculos?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res){
                setUsuario(res)
                if(res.detalles_conductor){
                    if(typeof res.detalles_conductor === "object"){
                        if(res._id) setConductor(res.detalles_conductor)
                    }
                }
            }
            return setLoadingMaster(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingMaster(false)
        })
    }
    
    const eliminarRecurso = async () => {
        setLoadingMaster(true)
        return fetch(`${urlapi}/vehiculos?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){
                return window.location = rutas.vehicles.slug
            }
            return setLoadingMaster(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingMaster(false)
        })
    }

    useEffect(() => {
        obtenerDatos()
    }, [])

    const handleChangeSelectSimple = (e,name) => {
        usuario[name] = e
        return setUsuario({...{}, ...usuario})
    }
    const handleChangeVehiculoCustom = (e,name) => {
        console.log({e,name})
        usuario[name] = e._id
        if(name === "id_tipo") usuario.tipo = e.tipo
        if(name === "marca") usuario.modelo = ""
        console.log(usuario)
        return setUsuario({...{}, ...usuario})
    }

    const seleccionarConductor = (data) => {
        
        return setUsuario(prev => {
            let actual = {...prev}
            let val = data?._id
            if(Array.isArray(data)) val = data[0]?._id
            actual.id_usuario = val
            return actual
        })
    }

    const handleChangeSelect = (e,name) => {
        usuario[name] = e
        return setUsuario({...{}, ...usuario})
    }

    const handleChangeUsuario = (e) => {
        const { name, value } = e.target
        if(name === "rut"){
            usuario[name] = limpiarStringLetrasNumeros(value)
        } else if(name === "phone"){
            usuario[name] = limpiarStringNumeros(value)
        } else {
            usuario[name] = value
        }
        return setUsuario({...{}, ...usuario})
    }

    const uploadButton = (
        <div>
          {loadingImagen ? <LoadingOutlined /> : <PlusOutlined />}
          <div
            style={{
              marginTop: 8,
            }}
          >
            Subir foto
          </div>
        </div>
      );

    const handleChangeUsuarioSelect = (name, value) => {
        usuario[name] = value
        return setUsuario(usuario)
    }

    const crearConductorNuevo = async () => {
        
        const instancia_vehiculo = { ...usuario }
        const calculo_volumen = calcularVolumen(usuario, usuario.unidad_medida )
        if( calculo_volumen.volumen && calculo_volumen.unidadVolumen){
            const volumen_calculado = convertirVolumenAMetorCubicios(calculo_volumen.volumen, calculo_volumen.unidadVolumen)
            instancia_vehiculo.volumen = volumen_calculado
        }

        let faltantes = []
        requeridos.map(campo => {
            if(!instancia_vehiculo[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return messageApi.error(`Faltan campos: ${faltantes.join(', ')}`)  
            instancia_vehiculo.autorizado = "si"

        setLoading(true)
        const url = `${urlapi}/vehiculos`
        return fetch(url, {
            method: "PUT",
            body: JSON.stringify(instancia_vehiculo),
            headers: {
                'Content-type': "application/json",
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(async res => {
            if(!res){
                messageApi.error("Sin datos obtenidos")
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){
                messageApi.success("Actualizado exitosamente")
                
            }
            return setLoading(false)
        })
        .catch(error => {
            console.log(error.message)
            messageApi.error("No se pudo efectuar la operación")
            return setLoading(false)
        })
    }

    const cambiarVehiculoConductor = async () => {
        setLoadingDefinicion(true)
        return fetch(`${urlapi}/vehiculos/definir-seleccionado`, {
            method: 'POST',
            body: JSON.stringify({
              id: usuario._id
            }),
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer: ${session.tokenSession}`,
              'country': pais,
              'lang': idioma
            },
          })
            .then((res) => {
              if (res.status === 401) return dispatch(cerrarSesion());
              return res.json();
            })
            .then((res) => {
              if (!res) {
                messageApi.error('Sin datos');
              } else if (res.errorMessage) {
                messageApi.error(res.errorMessage);
              } else if (res._id) {
                usuario.selected = true
                messageApi.success("Realizado exitosamente")
                setUsuario(prev => ({...{}, ...usuario}));
              }
              return setLoadingDefinicion(false);
            })
            .catch((error) => {
              messageApi.error('Error al consultar la información, intente nuevamente');
              return setLoadingDefinicion(false);
            })
      }

      const problemasConductor = () => {
        console.log({ conductor })
        if(conductor) if(usuario.id_usuario) return true
        return false
      }

      const informacionConductor = () => {

        return <Alert
        message="ATENCIÓN"
        showIcon
        className="mb-3"
        description="El conductor designado para este vehículo no se pudo encontrar"
        type="error"
        action={false}
      />
      }

    const autoSeleccionado = () => {

        if( conductor ) if(usuario.selected !== true) return <Alert
        message="ATENCIÓN"
        showIcon
        className="mb-3"
        description="Este no es el vehículo actual de este conductor"
        type="info"
        action={
            <Button
            className="mb-3"
            loading={loadingDefinicion}
            variant="outlined"
            onClick={() => cambiarVehiculoConductor()}
            >DEFINIR COMO VEHÍCULO ACTUAL</Button>
        }
      />

      if(conductor)  if(usuario.selected === true) return <Alert
        message="ATENCIÓN"
        showIcon
        className="mb-3"
        description="Este vehículo es el vehículo actual de este conductor"
        type="success"
        action={false}
        />
    
    //   const problemas = problemasConductor()
     
    //   if(problemas === true) return <div className="mb-3">
    //     <Alert type="info" message="Este vehículo es el vehículo actual de este conductor" />
    // </div> 
    //     return informacionConductor()   

      }

    const handleChangeArchivo = async (e) => {

        const archivo = e.file.originFileObj
        setLoadingImagen(true)
        return S3FileUpload.uploadFile(archivo, config_s3)
        .then( async data => {
            console.log(data)
        if(!data.location) {
            messageApi.error('No pudimos cargar tu imagen')
            return setLoadingImagen(false)
        }

        usuario.imagen_perfil = data.location
        setUsuario({...{}, ...usuario})
        return setLoadingImagen(false)

        })
        .catch(err => {
            console.log(err)
            setLoadingImagen(false)
            return messageApi.error('No pudimos cargar tu imagen')
        })
    }

    const render = () => {
        if(loadingMaster) return <Card><CargandoTabla /></Card>
        if(!usuario) return <Card><Title>No hay suficientes datos para mostrar esta pantalla</Title> </Card>

        const calculo_volumen = calcularVolumen(usuario, usuario.unidad_medida )

        return <div>
            <Card className="mb-3">
            <Breadcrumb
                items={[
                {
                    href: rutas.dashboard.slug,
                    title: <HomeOutlined />,
                },
                {
                    href: rutas.vehicles.slug,
                    title: <span>Vehículos</span>,
                },
                {
                    title: 'Detalles de vehículo',
                },
                ]}
            />
            <Title className="mb-2 mt-0">Detalles de vehículo</Title>
            <Popconfirm
                    title="Eliminar definitivamente"
                    description="¿Estás seguro que deseas eliminar este recurso? Esta opción no se puede deshacer"
                    onConfirm={() => eliminarRecurso()}
                    onCancel={() => false}
                    okText="SI"
                    cancelText="NO"
                  >
                  <Button size="small" type="dashed" danger>ELIMINAR</Button>
                  </Popconfirm>
            </Card>
            <Layout style={{ padding: 20 }}>
            <Row gutter={20}>
                <Col md={12}>
                        <Card size="small">
                    <ModalSeleccionVehiculos onRemove={() => {
                        setConductor(false)
                        usuario.id_usuario = ''
                        usuario.selected = false
                        return setUsuario({...{}, ...usuario})
                    }} defaultValue={conductor} onSelect={data => seleccionarConductor(data)} />
                    <Divider />
                {autoSeleccionado()}
            <Form layout="vertical">
            <Row gutter={10}>
                <Col md={8} xs={24}>
                    <Form.Item label="Patente" >
                    <Input mb={3} name="patente" defaultValue={usuario.patente} onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                
                <Col md={8} xs={24}>
                    <Form.Item label="Número de motor" >
                    <Input mb={3}  variant="filled" name="numero_motor" defaultValue={usuario.numero_motor}  onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                    <Form.Item label="Año" >
                    <Input mb={3} variant="filled" type="number" name="ano" defaultValue={usuario.ano} onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                    <Form.Item label="Marca" >
                        <SelectorMarcas onChange={(e) => handleChangeVehiculoCustom(e,"marca")} defaultValue={usuario.marca} />
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                    <Form.Item label="Modelo" >
                        <SelectorModelo filterBy={usuario.marca} value={usuario.modelo} filterByKey="id_marca" onChange={(e) => handleChangeVehiculoCustom(e,"modelo")} />
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                    <Form.Item label="Color" >
                        <SelectorColor onChange={(e) => handleChangeVehiculoCustom(e,"color")} defaultValue={usuario.color} />
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                    <Form.Item label="Tipo " >
                        <SelectorTipo onChange={(e) => handleChangeVehiculoCustom(e,"id_tipo")} defaultValue={usuario.id_tipo} />
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                    <Form.Item label="Tipo de motor" >
                        <SelectorTipoMotor onChange={(e) => handleChangeVehiculoCustom(e,"tipo_motor")} defaultValue={usuario.tipo_motor} />
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                    <Form.Item label="Número de chasis" >
                    <Input mb={3} variant="filled" name="chasis" defaultValue={usuario.chasis} onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                    <Form.Item label="Tamaño" >
                    <Select className="mb-0" name="size" placeholder="Selecciona" style={{ width: "100%" }} defaultValue={usuario.size ? tamanosCamion.find(e => e.value === usuario.size) : null } options={tamanosCamion} onChange={(e) => handleChangeSelectSimple(e,'size')} allowClear />
                    </Form.Item>
                </Col>
                <Col xs={24}>
                    <Title level={4}>Capacidad de carga</Title>
                </Col>
                <Col md={24} xs={24}>
                    <Form.Item label="Unidad de medida de longitud" required={siEsRequeridoEnEsquema('unidad_medida',requeridos)} >
                        <Select defaultValue={usuario.unidad_medida} options={unidades_medida.map(e => ({ value: e.code, label: e.unit }))} onChange={(e) => handleChangeSelect(e,"unidad_medida")} />
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                    <Form.Item label="Ancho" >
                    <Input mb={3} addonBefore={usuario.unidad_medida} variant="filled" type="number" name="ancho" defaultValue={usuario.ancho} onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                    <Form.Item label="Alto" >
                    <Input mb={3} addonBefore={usuario.unidad_medida} variant="filled" type="number" name="alto" defaultValue={usuario.alto} onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                    <Form.Item label="Largo" >
                    <Input mb={3} addonBefore={usuario.unidad_medida} variant="filled" type="number" name="largo" defaultValue={usuario.largo} onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                    <Form.Item label="Unidad de medida de peso" required={siEsRequeridoEnEsquema('unidad_medida_peso',requeridos)} >
                        <Select defaultValue={usuario.unidad_medida_peso} options={unidades_medida_peso.map(e => ({ value: e.code, label: e.unit }))} onChange={(e) => handleChangeSelect(e,"unidad_medida_peso")} />
                    </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                    <Form.Item label="Capacidad de peso" >
                    <Input mb={3} addonBefore={usuario.unidad_medida_peso} variant="filled" type="number" name="peso" defaultValue={usuario.peso} onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                    <Form.Item label="Tipo de pallet" required={siEsRequeridoEnEsquema('tipo_pallet',requeridos)} >
                    <Select variant="filled" defaultValue={usuario.tipo_pallet} allowClear options={tipos_pallet} onChange={(e) => handleChangeSelect(e,"tipo_pallet")} />
                    </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                <Form.Item label="Cantidad de pallets" required={siEsRequeridoEnEsquema('cantidad_pallet',requeridos)} >
                    <Input mb={3} variant="filled" defaultValue={usuario.cantidad_pallet} name="cantidad_pallet" onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                    <Title className="mt-0 mb-0" level={4}>Volumen</Title>
                    <Title className="mt-0 mb-3 " level={4}>{calculo_volumen.volumen.toFixed(2)} <Tag>{calculo_volumen.unidadVolumen}</Tag> </Title>
                </Col>
                <Col md={8} xs={24}>
                    <Title className="mt-0 mb-0" level={4}>Volumen</Title>
                    <Title className="mt-0 mb-3 " level={4}>{convertirVolumenAMetorCubicios(calculo_volumen.volumen, calculo_volumen.unidadVolumen).toFixed(2)} <Tag>M3</Tag> </Title>
                </Col>
                <Col md={8} xs={24}>
                    <Title className="mt-0 mb-0" level={4}>Peso</Title>
                    <Title className="mt-0 mb-3 " level={4}>{convertirAKilogramos(usuario.peso ? usuario.peso : 0, usuario.unidad_medida_peso)} <Tag>KG</Tag> </Title>
                </Col>
                {/* <Col md={12} xs={24}>
                    <Form.Item label="Unidad de medida de volumen" required={siEsRequeridoEnEsquema('unidad_medida_volumen',requeridos)} >
                        <Select defaultValue={usuario.unidad_medida_volumen} options={unidades_medida_volumen.map(e => ({ value: e.code, label: e.unit }))} onChange={(e) => handleChangeSelect(e,"unidad_medida_volumen")} />
                    </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                    <Form.Item label="Volumen de carga" >
                    <Input mb={3} addonBefore={usuario.unidad_medida_volumen} variant="filled" type="number" name="volumen" defaultValue={usuario.volumen} onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col> */}
                <Col md={24} xs={24}>
                    <Form.Item label="Estado" >
                    <Select className="mb-0" name="status" placeholder="Estado" style={{ width: "100%" }} defaultValue={usuario.status} options={[{ value: "active", label: "Activo" }, { value: "trash", label: "Inactivo"}]} onChange={(e) => handleChangeSelectSimple(e,'status')} />
                    </Form.Item>
                    <Card size="small" className="mt-3 mb-3">
                    <Title level={4} className="mb-0 mt-3">Calificación en plataforma Moving Truck Cliente</Title>
                    <Rate className="mb-3" defaultValue={usuario.rating || 0 } disabled />
                    </Card>
                </Col>
            </Row>     
               
            <Button type="primary" loading={loading} style={{ width: "100%" }} mt={3} colorScheme="green" onClick={() => crearConductorNuevo()} ><SaveOutlined /> GUARDAR CAMBIOS</Button>
            </Form>
            </Card>

                </Col>
                <Col md={12}>
                    <Card size="small">
                    <Tabs defaultActiveKey="1" items={[
                        {
                            key:"1",
                            label: <div style={{ textAlign: "center" }}><TbFileCertificate  style={{ fontSize: 20 }} /> <Title level={4} className="m-0">REQUISITOS</Title></div>,
                            children: <div>
                                <ListadoRequisitos condicion_default={{ id_target: usuario._id }}/>
                                <Divider/>
                                <Title level={4} className="mb-0 mt-0">Otros requisitos que podrías agregar</Title>
                                <ListadoRequisitosModelos id_target={usuario._id} condicion_default={{ tipo: "vehiculo", status: "active" }} />
                            </div> 
                        },
                        {
                            key: "2",
                            label: <div style={{ textAlign: "center" }}><TbTag style={{ fontSize: 20 }} /> <Title level={4} className="m-0">MÁS INFORMACIÓN</Title></div>,
                            children: <CamposPersonalizadosRecurso id_target={id} tipo="vehiculo" />
                        },
                        {
                            key: "3",
                            label: <div style={{ textAlign: "center" }}><TbTag style={{ fontSize: 20 }} /> <Title level={4} className="m-0">EQUIPOS</Title></div>,
                            children: <EquiposRelaciones id_vehiculo={usuario._id}  />
                        },
                        {
                            key: "4",
                            label: <div style={{ textAlign: "center" }}><TbTag style={{ fontSize: 20 }} /> <Title level={4} className="m-0">AGENDA</Title></div>,
                            children: <div>
                                <AgendaRecurso condicino_default={{ id_vehiculo: "64b1e8dd8b210c6ad6049936"}} />
                            </div>
                        },
                        {
                            key: "5",
                            label: <div style={{ textAlign: "center" }}><TbTag style={{ fontSize: 20 }} /> <Title level={4} className="m-0">CALIFICACIONES</Title></div>,
                            children: <div>
                                <ListadoCalificaciones showFilters={false} hideSearch={true} condicion_default={{ id_vehiculo: usuario._id }} />
                            </div>
                        },
                        {
                            key: "6",
                            label: <Card size="small" style={{ textAlign: "center" }}><TbGps style={{ fontSize: 20 }} /> <Title level={4} className="m-0">GPS</Title></Card>,
                            children: <div>
                                  <CamposPersonalizadosRelacionador id_target={usuario._id} condicion_default={{ module: "vehiculo" }}  />
                                </div>
                        },
                        
                    ]} />
                    </Card>
                </Col>
            </Row>
            </Layout>
            {contextHolder}
        </div>
    }
    
    return <Structure component={render()} />
}

export default DetallesVehiculo